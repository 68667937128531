import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import { ContentContainer } from '../Components/Common/ContentContainer';
import { PlayCircleOutline } from '@styled-icons/material-twotone';
import { Buttons } from '../Components/Common/Styles/Interactions';


const IndustriesSection = styled(ContentContainer)`
    display: flex;
    flex-wrap: wrap;
    background-color: ${ props => props.theme.colors.nearlyNavy };
    padding-top: 2vw;
    padding-bottom: 2vw;

    .industries__intro,
    .industry--dropdown {
        p {
            font-size: 16px;
            font-weight: 300;
        }

        p:first-of-type {
            font-size: 20px;
            line-height: 24px;
            font-weight: 400;
        }
    }

    article,
    section {
        width: 100%;

        > * {
            font-style: normal;
            font-family: ${props => props.theme.fonts.din};
        }
    }

    article {
        flex-basis: 100%;
        padding-bottom: .75rem;

        @media (min-width: ${props => props.theme.screensizes.medium}) {
            flex-basis: 40%;
            padding-right: .75rem;
            padding-bottom: 0;
        }
    }

    section {
        flex-basis: 100%;
        padding-top: .75rem;

        @media (min-width: ${props => props.theme.screensizes.medium}) {
            flex-basis: 60%;
            padding-left: .75rem;
            padding-top: 0;
        }

        .industry {
            width: 100%;
            position: relative;
            padding-bottom: 1rem;

            &.is-open {
                svg {
                    transform: translateY(-50%) rotate(-90deg);
                }

                .industry--dropdown, .industry--actions {
                    opacity: 1;
                    visibility: visible;
                    padding: 2vw;
                    max-height: 999999999px;
                }
            }

            .industry--title {
                min-height: 128px;
                background-size:cover;
                font-size: 24px;
                color: ${props => props.theme.colors.almostWhite};
                padding: 0 25px;
                position: relative;
                display: flex;
                align-items: center;

                @media (min-width: ${props => props.theme.screensizes.medium}) {
                    font-size: 32px;
                }
            }

            .industry--dropdown, .industry--actions {
                background-color: #ffffff;
                max-height: 0;
                opacity: 0;
                visibility: hidden;
                overflow: hidden;
                transition: all .3s ease-in-out;
                padding: 0vw 2vw;
            }

            .industry--dropdown {
                > * {
                    color: ${props => props.theme.colors.nearlyNavy};
                }

                p {
                    margin-bottom: 1.5rem;
                }
            }
            .industry--actions {
                padding-top: 0 !important;

                button {
                    padding: 16px;
                    font-weight: bold;
                    font-family: ${props => props.theme.fonts.din};
                    font-size: 18px;
                    text-align: center;
                    line-height: 1;
                    width: 100%;
                    max-width: 180px;
                }

                > :first-child {
                    color: ${props => props.theme.colors.nearlyNavy};
                    background-color: ${props => props.theme.colors.meaningfulMarigold};
                }

                > :last-child:not(:only-child) {
                    color: #ffffff;
                    background-color: transparent;
                    margin-left: 25px;
                    border: 1px solid ${props => props.theme.colors.nearlyNavy};
                    color: ${props => props.theme.colors.nearlyNavy};
                }
            }
        }
    }

    h2, h3 {
        margin: 1rem 0;
        text-align: left;
        text-transform: uppercase;
    }

    h2 {
        color: ${props => props.theme.colors.meaningfulMarigold};
        font-size: 20px;
        font-weight: 800;
        margin-top: 0;
    }

    h3 {
        font-size: 48px;
        font-family: ${props => props.theme.fonts.dinNarrow};
        font-weight: 700;
    }

    h3, p {
        color: ${props => props.theme.colors.almostWhite};
    }

    p {
        font-size: 16px;
        font-weight: 300;
    }
`;

const SubmitIcon = styled(PlayCircleOutline)`
    width: 24px;
    transform: translateY(-50%) rotate(90deg);
    position: absolute;
    right: 25px;
    top: 50%;
    fill: ${props => props.theme.colors.meaningfulMarigold};
`;

const IndustryBackgroundStyles = styled.aside<{ backgroundUrl: string }> `
    background:linear-gradient(0deg, rgba(29,35,64, 0.65), rgba(29,35,64, 0.65)), url('${ props => props.backgroundUrl }');
`

interface IActionDetails {
    link: string;
    name: string;
}

interface IIndustriesDetails {
    industryTitle: string;
    industryBackgroundImage: string;
    industryContent: string;
    industryActions: Array<IActionDetails>;
}

interface IIndustryDetails {
    title: string;
    content: string;
    industries: Array<IIndustriesDetails>;
}

const IndustriesPage: React.FC<{}> = () => {
    const handleClick = (event: React.MouseEvent) => {
        event.preventDefault();

        const { currentTarget } = event;
        const dropdown = currentTarget.closest('.industry');
        if (dropdown?.classList.contains('is-open')) return dropdown.classList.remove('is-open');
        dropdown?.classList.add('is-open');
    }

    let query = useStaticQuery<GatsbyTypes.IndustriesQuery>(industriesQuery)

    const content = new Array<IIndustryDetails>()
    query?.wordpress?.pages?.nodes?.forEach((el:any) => {
        content.push({
            title: el?.title || '',
            content: el?.content || '',
            industries: el?.pageIndustriesCustomFields?.industries?.map((industry:any) => {
                return {
                    industryTitle: industry?.title || '',
                    industryBackgroundImage: industry?.backgroundImage?.sourceUrl || '',
                    industryContent: industry?.content || '',
                    industryActions: industry?.actions?.map((action:any) => {
                        return {
                            link: action?.link || '',
                            name: action?.name || '',
                        }
                    }),
                }
            }) || new Array<IIndustriesDetails>(),
        })
    })

    const data = (content.find(c => c?.title === 'Industries'));
    if ( !data ) return null

    return (
        <IndustriesSection>
            <article>
                { data?.title && (
                    <h2>{ data.title }</h2>
                )}
                <h3>Bespoke solutions for your industry</h3>
                { data?.content && (
                    <aside className="industries__intro" dangerouslySetInnerHTML={{__html: data?.content}}></aside>
                )}
                <Buttons.Primary text="Explore Our Solutions" href="https://www.gasmonitor-point.co.uk/" />
            </article>
            { data?.industries && (
                <section>
                    { data.industries.map((industry, index) => (
                        <div key={'industry__' + index}>
                            <article className="industry">
                                <IndustryBackgroundStyles className="industry--title"
                                    backgroundUrl={ industry?.industryBackgroundImage }
                                >
                                    { industry.industryTitle }
                                    <SubmitIcon onClick={handleClick}/>
                                </IndustryBackgroundStyles>
                                <aside className="industry--dropdown" dangerouslySetInnerHTML={{__html: industry.industryContent}}></aside>
                                { industry.industryActions && (
                                    <aside className="industry--actions">
                                        {industry.industryActions.map((action, i) => (
                                            <a href={ action.link } className="button" key={'action__'+ i}>{ action.name }</a>
                                        ))}
                                    </aside>
                                )}
                            </article>
                        </div>
                    ))}
                </section>
            )}
        </IndustriesSection>
    )
};

const industriesQuery = graphql`
    query Industries {
        wordpress {
            pages(where: {name: "Industries"}) {
                nodes {
                    id
                    title
                    content
                    pageIndustriesCustomFields {
                        industries {
                            backgroundImage {
                                sourceUrl
                            }
                            actions {
                                text
                                link
                            }
                            content
                            title
                        }
                    }
                }
            }
        }
    }
`;

export default IndustriesPage;